/** @jsxImportSource @emotion/react */
import React from 'react';
import { css, SerializedStyles } from '@emotion/react';
import { colors } from 'src/constants/variables';
import { noop } from 'src/helpers/utils';

interface Props {
    name: string | JSX.Element;
    customCss?: SerializedStyles;
    shift?: boolean;
    onNameClick?: () => void;
    showLabelTooltip?: boolean;
    largeChildren?: boolean;
}

export const DataField: React.FC<Props> = ({
    name,
    customCss,
    shift,
    onNameClick = noop,
    largeChildren,
    children,
    ...rest
}) => (
    <div css={[styles.wrapper, customCss]} {...rest}>
        <span
            css={[
                styles.name,
                shift &&
                    css`
                        margin-bottom: 26px;
                    `,
            ]}
            onClick={onNameClick}
        >
            {name}
        </span>
        <div css={largeChildren ? styles.largeChildren : styles.children}>{children}</div>
    </div>
);

const styles = {
    wrapper: css`
        display: flex;
        align-items: center;
        justify-content: space-between;

        height: 56px;
        margin: 16px 0;
        font-family: Gebauer-Regular, sans-serif;
    `,
    name: css`
        color: ${colors.fontHeaderSecondary};
        font-size: 16px;
        line-height: 1;
        text-transform: uppercase;
    `,
    children: css`
        width: 50%;
    `,
    largeChildren: css`
        width: 75%;
    `,
};
