/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';

import { css } from '@emotion/react';
import { redCustomCheckboxStyle } from '../../styles';
import { Checkbox, Form, FormInstance } from 'antd';
import { NumberInput, simpleInputCss } from '../../../../../components/DataInputs';
import { DataField } from '../../../../../components/DataField';
import { AdditionalServiceItem } from './types';
import { MAX_PRICE_VALUE } from '../../../../../constants';
import { OfferFormProps } from '../../types';
import { serviceItemName } from './serviceItemName';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

export type MBKServiceItemProps = {
    serviceData: AdditionalServiceItem;
    form: FormInstance<OfferFormProps>;
};

export const MBKServiceItem: React.FC<MBKServiceItemProps> = ({ serviceData, form }) => {
    const formIsChecked = form.getFieldValue(serviceData.name);
    const [isChecked, setIsChecked] = useState(formIsChecked);

    function handleCheckboxChange(e: CheckboxChangeEvent) {
        setIsChecked(e.target.checked);
    }

    return (
        <div css={styles.wrapper}>
            <Form.Item name={serviceData.name} valuePropName="checked">
                <Checkbox
                    css={redCustomCheckboxStyle.checkbox}
                    data-test={serviceData.name}
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                >
                    {serviceItemName(serviceData.label)}
                </Checkbox>
            </Form.Item>

            {isChecked && (
                <div css={styles.inputWrapper}>
                    {serviceData.inputs.map((input, index) => (
                        <DataField
                            key={index}
                            css={styles.inputItem}
                            shift
                            name={input.label}
                            largeChildren={true}
                            data-test={input.name}
                        >
                            <Form.Item name={input.name}>
                                <NumberInput
                                    required={isChecked}
                                    addComma={input.isPrice}
                                    step={input.isPrice ? 0.01 : 1}
                                    precision={input.isPrice ? 2 : 0}
                                    min={input?.min ? input.min : 0}
                                    max={MAX_PRICE_VALUE}
                                    css={[simpleInputCss]}
                                    allowNegative={false}
                                    currencySuffix={input.isPrice}
                                />
                            </Form.Item>
                        </DataField>
                    ))}
                </div>
            )}
        </div>
    );
};

const styles = {
    wrapper: css`
        margin-top: 18px;
    `,
    inputWrapper: css`
        display: flex;
        justify-content: space-between;
    `,
    inputItem: css`
        flex-grow: 1;
        flex-basis: 0;
    `,
};
